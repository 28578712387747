<template>
    <div>
      <div class="d-flex">
        <v-btn
          color="#9DC033"
          @click="save"
          width="200"
          height="55"
          dark
        >
          Сохранить
        </v-btn>
        <v-btn
          class="ml-3"
          color="#CFD0C9"
          @click="close"
          width="200"
          height="55"
          dark
        >
          Закрыть
        </v-btn>
      </div>
    <v-card
      color="#F7F7F6"
    >
      <v-card-text>
        <div class="row--dense" style="border: 1px solid;">
          <div class="d-flex justify-space-between align-center"
               style="border-bottom: 1px solid; height: 55px; max-height: 55px"
          >
            <div class="d-flex col-4 justify-start">
              <h1 class="pr-5">Товар</h1>
              <div>{{ (item && item.id) ? `#${item.id}`: ''}}</div>
            </div>
            <div v-if="!isNew" class="d-flex col-6 justify-space-between">
              <div class="d-flex flex-column" >
                <span>Автор: {{ userCreated ? userCreated : '' }}</span>
                <span>Дата и время:
                {{ (item && item.createdDate) ? dateFormatter(item.createdDate) : ''}}</span>
              </div>
              <div class="d-flex flex-column">
                <span>Редактор: {{ userModified ? userModified : ''}}</span>
                <span>Дата и время:
                  {{ (item && item.lastModifiedDate) ? dateFormatter(item.lastModifiedDate) : ''}}
                </span>
              </div>
            </div>

          </div>
          <div
          >
            <v-form ref="form">
              <div class="d-flex">
                <div class="d-flex col-5 flex-column mr-10">
                  <div class="d-flex">
                    <span class="col-4">
                      Наименование товара
                    </span>
                    <v-text-field
                      class="col-8"
                      solo
                      v-model="item.nameRu"
                    >
                    </v-text-field>
                  </div>
                  <div class="d-flex">
                    <span class="col-4">
                      Полное наименование товара
                    </span>
                    <v-text-field
                      class="col-8"
                      solo
                      v-model="item.fullNameRu"
                    >
                    </v-text-field>
                  </div>
                </div>
                <div class="d-flex col-5 flex-column">
                  <div class="d-flex">
                    <span class="col-4">
                      Наименование товара на Казахском
                    </span>
                    <v-text-field
                      class="col-8"
                      solo
                      v-model="item.nameKk"
                    >
                    </v-text-field>
                  </div>
                  <div class="d-flex">
                    <span class="col-4">
                      Полное наименование товара на Казахском
                    </span>
                    <v-text-field
                      class="col-8"
                      solo
                      v-model="item.fullNameKk"
                    >
                    </v-text-field>
                  </div>
                </div>
              </div>
<!--              <div>-->
<!--                <h1> IMAGE BLOCK</h1>-->
<!--              </div>-->

              <div>
                <div class="flex-column col-5">
                  <div class="d-flex">
                    <span class="col-4">
                      Артикул
                    </span>
                    <v-text-field
                      class="col-8"
                      solo
                      v-model="item.vendorCode"
                    />
                  </div>
                  <div class="d-flex">
                    <span class="col-4">
                      Категория
                    </span>
                    <v-select
                      class="col-8"
                      solo
                      :items="categories"
                      item-text="nameRu"
                      item-value="id"
                      v-model="item.categoryId"
                    />
                  </div>
                  <div class="d-flex">
                    <span class="col-4">
                      Страна
                    </span>
                    <v-text-field
                      class="col-8"
                      solo
                      v-model="item.country"
                    />
                  </div>
                  <div class="d-flex">
                    <span class="col-4">
                      Единица измерения
                    </span>
                    <v-select
                      class="col-8"
                      solo
                      v-model="item.measureType"
                      :items="measureTypeList"
                      item-text="label"
                      item-value="prop"
                    />
                  </div>
                  <div class="d-flex">
                    <span class="col-4">
                      Вес/объем/количество
                    </span>
                    <v-text-field
                      class="col-8"
                      solo
                      v-model="item.weight"
                    />
                  </div>
                  <div class="d-flex">
                    <span class="col-4">
                      Бренд
                    </span>
                    <v-text-field
                      class="col-8"
                      solo
                      v-model="item.brand"
                    />
                  </div>
                </div>
              </div>
              <div class="d-flex wrap justify-start">
                <div class="d-flex flex-column col-5 mr-10">
                  <span class="pb-5">Описание на Русском</span>
                  <v-textarea solo v-model="item.descriptionRu"/>
                </div>
                <div class="d-flex flex-column col-5">
                  <span class="pb-5">Описание на Казахском</span>
                  <v-textarea solo v-model="item.descriptionKk"/>
                </div>
              </div>
            </v-form>
          </div>
        </div>
      </v-card-text>
    </v-card>
    </div>
</template>

<script>
import moment from 'moment';

export default {
  data() {
    return {
      valid: true,
      item: {
        nameRu: null,
        nameKk: null,
        fullNameRu: null,
        fullNameKk: null,
        vendorCode: null,
        categoryId: null,
        country: null,
        measureType: null,
        brand: null,
        descriptionRu: null,
        descriptionKk: null,
        createdBy: null,
        createdDate: null,
        lastModifiedBy: null,
        lastModifiedDate: null,
      },
      defaultItem: {
        nameRu: null,
        nameKk: null,
        fullNameRu: null,
        fullNameKk: null,
        vendorCode: null,
        categoryId: null,
        country: null,
        measureType: null,
        brand: null,
        descriptionRu: null,
        descriptionKk: null,
        createdBy: null,
        createdDate: null,
        lastModifiedBy: null,
        lastModifiedDate: null,
      },
      measureTypeList: [
        { prop: 'PER_UNIT', label: 'единица' },
        { prop: 'PER_KG', label: 'кг' },
        { prop: 'PER_LITER', label: 'литр' },
      ],
      productId: -1,
      isNew: false,
      userCreated: null,
      userModified: null,
      // rules: [
      //   (v) => !!v || 'Name is required',
      //   (v) => (v && v.length > 3) || 'Name must be more than 3 characters',
      // ],
    };
  },
  created() {
    if (!this.$route.query.isNew) {
      this.productId = this.$route.query.productId;
      this.item = { ...this.defaultItem };
      if (this.productId > -1) {
        this.item = { ...this.products.find((i) => i.id === this.productId) };
      } else {
        this.item.categoryId = this.$route.query.categoryId;
      }
      this.setUserInformation();
    }
    this.isNew = this.$route.query.isNew;
    this.$router.replace({});
  },
  computed: {
    categories() {
      return this.$store.getters['nomenclatures/getCategories'];
    },
    products() {
      return this.$store.getters['nomenclatures/getProducts'];
    },
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    save() {
      this.$loading(true);
      let method;
      if (this.productId > -1) {
        method = 'put';
      } else {
        method = 'post';
      }
      this.$api[method]('/api/v1/wms/products', { ...this.item }).then(() => {
        this.reset();
        this.close();
      }).catch((e) => {
        this.$log.warn(e);
      }).finally(() => {
        this.$loading(false);
      });
    },
    dateFormatter(val) {
      return moment(val).format('DD-MM-YYYY HH:mm');
    },
    close() {
      this.$router.replace({
        name: 'nomenclature',
      });
    },
    getUserInfo(id) {
      return this.$api.get(`/api/v1/crm/users/${id}`);
    },
    setUserInformation() {
      this.getUserInfo(this.item.createdBy).then((response) => {
        this.$nextTick(() => {
          this.userCreated = `${response.firstName} ${response.lastName}`;
        });
      });
      this.getUserInfo(this.item.lastModifiedBy).then((response) => {
        this.$nextTick(() => {
          this.userModified = `${response.firstName} ${response.lastName}`;
        });
      });
    },
  },
};
</script>

<style scoped>
span {
  font-family: Open Sans,serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  /* Text black */

  color: #7E8271;
}
</style>
